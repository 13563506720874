.bingo-container {
	display: block;
	scrollbar-gutter: stable;
	overflow: auto;
	
	max-height: calc(100vh - var(--navbar-height));
	margin: 1em;
}

.bingo-play-area {
	text-align: center;
	max-width: 100%;
}

.bingo-board {
	margin: 1em auto;
	user-select: none;
	
	table-layout: fixed;
	border: 5px outset var(--secondary-color);
}

.bingo-board tr td {
	--board-size: 105px;
	aspect-ratio: 1;
	
	height: var(--board-size);
	width: var(--board-size);
	
	text-wrap: wrap;
	background-color: gray;
	
	padding: 5px;
	
	&:hover {
		cursor: pointer;
	}
}


.bingo-button {
	background-color: var(--secondary-color);
	&:hover {
		background-color: rgb(from var(--secondary-color) calc(r + 15) calc(g + 15) calc(b + 15));
	}
}

.bingo-rules {
	max-width: fit-content;
	margin: 0 auto;
	padding: 2em;
}

.bingo-rules h1 {
	margin-left: -0.6em;
}


@container q-cont (width < 981px) {
	.bingo-board tr td {
		--board-size: 90px;
		font-size: 0.9em;
	}
}

@container q-cont (width < 580px) {
	.bingo-board tr td {
		--board-size: 75px;
		font-size: 0.8em;
	}
}

@container q-cont (width < 460px) {
	.bingo-board tr td {
		--board-size: 60px;
		font-size: 0.7em;
	}
}

@container q-cont (width < 400px) {
	.bingo-board tr td {
		--board-size: 50px;
		font-size: 0.6em;
	}
}

@media screen and (max-height: 776px) {
	#width-limit:has(> .bingo) {
		display: block;
	}
}