.tile-link {
	all: unset;
	color: inherit;
	&:hover {
		color: rgb(235, 255, 235);
	}
}

/* Project Tiles */
.projects-container {
	padding: 1rem 0;
	gap: 1rem;
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(300px, 300px));
	align-items: center;
	justify-content: center;
	
	width: max(320px, 95%);
	margin: 0 auto;
}

.project-tile {
	box-sizing: border-box;
	
	font-size: 0.9em;
	
	backdrop-filter: blur(0px);
	background-color: rgb(from var(--secondary-color) r g b / 0.5);
	border: 2px solid var(--secondary-color);
	border-radius: 10px;
	
	padding: 1em;
	cursor: pointer;
	
	min-height: 300px;
	height: 300px;
	max-height: 300px;
	
	min-width: 300px;
	width: 300px;
	max-width: 300px;
	
	display: flex;
	flex-direction: column;
	position: relative;
	
	transition: 200ms;
	
	&:hover {
		background-color: rgb(from var(--secondary-color) calc(r + 10) calc(g + 10) calc(b + 10) / 0.5);
		border-color: rgb(from var(--secondary-color) calc(r + 10) calc(g + 10) calc(b + 10));
	}
}

.tile-header {
	display: flex;
	flex-direction: row;
	justify-content: center;
	gap: 1rem;
	
	align-items: center;
	text-align: center;
	
	width: 100%;
	min-height: 100px;
	height: 100px;
	max-height: 100px;
	
	margin-top: -5px;
	padding-bottom: 5px;
	margin-bottom: 5px;
	border-bottom: 2px solid var(--secondary-color);
}

.tile-title-container {
	position: relative;
	text-align: left;
	display: table;
	width: 100%;
}

.tile-title {
	display: table-cell;
	vertical-align: middle;
	text-align: left;
	
	width: 100%;
	height: 100%;
	
	margin: 0;
}

.tile-icon {
	width: 90px;
	height: 90px;
	justify-content: flex-end;
}

.tile-desc {
	border-radius: 0;
}

.projects-container.mobile {
	margin-top: 52px;
}
