.md-container {
	display: block;
	scrollbar-gutter: stable;
	overflow: auto;
	
	max-height: calc(100vh - var(--navbar-height));
}

.md-content {
	display: block;
	max-width: 900px;
	
	margin: 0 auto;
	padding: 0 2rem;
	margin-bottom: 5em;
}

.md-h1, .md-h2, .md-h3, .md-h4, .md-h5, .md-h6 {
	margin-left: 0.1em;
}

.md-h1 {
	text-align: center;
	font-size: 65px;
}

.md-h2 {
	margin-top: 1rem;
	margin-bottom: 0.25rem;
	font-size: 35px;
}

.md-h3 {
	margin-bottom: 0.25rem;
	font-size: 25px;
}

.md-ol, .md-ul {
	margin-bottom: 0.75rem;
}

.md-p {
	margin-bottom: 0.75rem;
	text-align: justify;
	margin-left: 0.25em;
}

.md-code {
	background-color: #3f3f3f;
	font-size: 0.75em;
	padding: 3px 5px 1px 6px;
	/* font-style: italic; */
	font-family: "Fira Code", monospace;
	vertical-align: middle;
	border: 0px solid transparent;
	border-radius: 4px;
}

.md-iframe {
	display: block;
	margin: 1.5rem auto 1.5rem auto;
	max-width: 100%;
}

@media screen and (max-width: 600px){
	.md-content {
		padding: 0 1em;
	}
	
	.md-p {
		text-align: left;
	}
}