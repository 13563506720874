.title-header {
	display: flex;
	flex-direction: row;
	max-height: 450px;
	gap: 3rem;
	align-items: center;
	justify-content: center;
	/* margin-top: 3em; */
	margin-bottom: 3em;
}


.really-big-text {
	font-size: 70px;
}


.mobile.title-header .really-big-text {
	font-size: 60px;
}


.gradient {
	width: fit-content;
	background: linear-gradient(to right, var(--primary-color), var(--accent-color));
	background-clip: text;
	color: transparent;
	font-style: italic;
	
}


.logo-container {
	min-height: 100%;
	margin: 0 10px;
}


.logo {
	display: block;
	max-height: 450px;
	fill: transparent;
	stroke: gray;
	stroke-width: 2;
}


.logo #logoPath {
	stroke: black;
	stroke-width: 2;
	
	stroke-dasharray: 579.33;
	stroke-dashoffset: 579.33;
	
	fill: black;
	fill-opacity: 0;
	
	animation: logoIconFill 2s ease-in 2.5s forwards;
}


.logo #circlePath {
	stroke: var(--secondary-color);
	stroke-width: 2;
	
	stroke-dasharray: 546.64;
	stroke-dashoffset: 546.64;
	
	fill: #93b693;
	fill-opacity: 0;
	
	animation: logoBGFill 2s ease-in 0.5s forwards;
}


.mobile.title-header {
	display: block;
	max-height: unset;
	margin-top: 2em;
}

.mobile.title-header .logo-container {
	max-width: 90%;
	margin: auto;
	margin-bottom: 3em;
}




@keyframes logoIconFill {
	50% {
		stroke-dashoffset: 0;
	}
	
	51% {
		fill-opacity: 0;
	}
	
	100% {
		fill-opacity: 1;
		stroke-dashoffset: 0;
	}
}


@keyframes logoBGFill {
	50% {
		stroke-dashoffset: 0;
		fill-opacity: 0;
	}
	
	100% {
		fill-opacity: 1;
		stroke-dashoffset: 0;
	}
}