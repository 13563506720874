.project {
	padding: 0;
}
.project-page {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: 1fr 1fr;
	padding: 0 1rem;
	height: calc(100vh - var(--navbar-height));
}

.project-page > section {
	padding: 1rem;
}


#project-links {
	position: relative;
	flex-direction: column;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: nowrap;
	gap: 0.5rem;
}

#project-info {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

}

.project-logo {
	width: 95%;
	max-width: 380px;
}

#project-buttons {
	display: flex;
	flex-direction: row;
	justify-content: center;
	gap: 0.25rem;
	align-items: center;
	text-align: center;
	width: 100%;
}

.project-button {
	text-decoration: none;
	border: #3a3a3a 2px solid;
	border-radius: 5px;
	color: var(--font-color);
	background-color: rgb(25, 27, 29);
	padding: 0.5em 1.25em;
	display: inline-table;
	
	&:hover {
		color: var(--font-color);
		border-color: #5a5a5a;
		background-color: rgb(35, 37, 39);
	}
}

.github-button::after {
	content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='0.8em' width='0.8em' fill='rgb(235, 235, 235)' viewBox='0 0 512 512'%3E%3Cpath d='M320 0c-17.7 0-32 14.3-32 32s14.3 32 32 32l82.7 0L201.4 265.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L448 109.3l0 82.7c0 17.7 14.3 32 32 32s32-14.3 32-32l0-160c0-17.7-14.3-32-32-32L320 0zM80 32C35.8 32 0 67.8 0 112L0 432c0 44.2 35.8 80 80 80l320 0c44.2 0 80-35.8 80-80l0-112c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 112c0 8.8-7.2 16-16 16L80 448c-8.8 0-16-7.2-16-16l0-320c0-8.8 7.2-16 16-16l112 0c17.7 0 32-14.3 32-32s-14.3-32-32-32L80 32z'/%3E%3C/svg%3E");
	margin-left: 0.5em;
}

.project-title {
	font-size: 2.2em;
	margin-bottom: 0.5em;
	white-space: pre-line;
	text-align: center;
}

.project-desc {
	max-width: 400px;
	margin-bottom: 1rem;
}

.project-extra-title {
	font-size: 2em;
	margin-bottom: 0.5em;;
}

@media screen and (max-width: 950px) {
	#width-limit:has(> .project) {
		display: block;
	}
	
	.project-page {
		display: block;
	}
  }

@media screen and (max-height: 2000px) {
	#width-limit:has(> .project) {
		display: block;
	}
}
